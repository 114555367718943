import React from 'react'
import { getThemeColor } from '../../theming/helpers'
import { meetsContrastGuidelines } from 'polished'
import { createPopper } from '@popperjs/core'
import { connectGeoSearch } from 'react-instantsearch-dom'
import { navigate } from 'gatsby'
import bbox from '@turf/bbox'
import center from '@turf/center'
import { maplibre, extractFeature, MAPTILER_TOKEN } from '../../utils/maps'
import PropertyCard from '../property-card'
import { FormattedMessage } from 'react-intl'

class Map extends React.Component {
  constructor(props) {
    super(props)

    this.propertiesGeoJSON = {
      type: 'FeatureCollection',
      features: this.props.properties.map(extractFeature).filter(Boolean),
    }

    if (this.propertiesGeoJSON.features.length > 0) {
      const point = center(this.propertiesGeoJSON)
      this.state = {
        lng: point.geometry.coordinates[0],
        lat: point.geometry.coordinates[1],
        zoom: 8,
        searchAsTheMapMoves: true,
      }
    } else {
      this.state = {
        lat: 46.7629421,
        lng: 4.2452205,
        zoom: 4,
      }
    }
    this.themeColor = getThemeColor(props.agencySettings)

    this.setClustersTextColor()
  }

  componentDidMount() {
    this.map = new maplibre.Map({
      container: this.mapContainer,
      style: `https://api.maptiler.com/maps/streets-v2/style.json?key=${MAPTILER_TOKEN}`,
      center: [this.state.lng, this.state.lat],
      zoom: this.state.zoom,
      fadeDuration: 0,
    })
    this.map.addControl(new maplibre.NavigationControl())
    this.map.on('load', this.showMarkers)
    this.setupPoppper()
  }

  showMarkers = () => {
    this.map.addSource('properties', {
      type: 'geojson',
      data: this.propertiesGeoJSON,
      cluster: true,
      clusterMaxZoom: 22,
    })

    this.map.addLayer({
      id: 'properties-clusters-shadow',
      type: 'circle',
      source: 'properties',
      filter: ['has', 'point_count'],
      paint: {
        'circle-radius': 26,
        'circle-color': 'rgba(0,0,0,.8)',
        'circle-blur': 2,
      },
    })

    this.map.addLayer({
      id: 'properties-clusters-circle',
      type: 'circle',
      source: 'properties',
      filter: ['has', 'point_count'],
      paint: {
        'circle-radius': 14,
        'circle-color': this.themeColor,
        'circle-stroke-color': '#ffffff',
        'circle-stroke-width': 2,
      },
    })

    this.map.addLayer({
      id: 'properties-shadow',
      type: 'circle',
      source: 'properties',
      filter: ['!', ['has', 'point_count']],
      paint: {
        'circle-radius': 12,
        'circle-color': 'rgba(0,0,0,.8)',
        'circle-blur': 1.5,
      },
    })

    this.map.addLayer({
      id: 'properties',
      type: 'circle',
      source: 'properties',
      filter: ['!', ['has', 'point_count']],
      paint: {
        'circle-radius': 7,
        'circle-color': this.themeColor,
        'circle-stroke-width': 1,
        'circle-stroke-color': '#ffffff',
      },
    })

    this.map.addLayer({
      id: 'properties-clusters-label',
      type: 'symbol',
      source: 'properties',
      filter: ['has', 'point_count'],
      layout: {
        'text-field': '{point_count_abbreviated}',
        'text-font': ['Arial Unicode MS Bold'],
        'text-size': 12,
        'symbol-z-order': 'source',
      },
      paint: {
        'text-color': this.textColor,
      },
    })

    this.setClusterEventHandlers()
    this.setMarkerEventHandlers()

    if (this.propertiesGeoJSON.features.length > 0) {
      const bounds = bbox(this.propertiesGeoJSON)

      this.map.fitBounds(bounds, {
        maxZoom: 8,
        padding: 40,
      })
    }

    this.map.on('dragend', () => {
      if (this.state.searchAsTheMapMoves) {
        this.refineToMapBounds()
      }
    })
  }

  setClustersTextColor() {
    const scores = meetsContrastGuidelines(this.themeColor, '#ffffff')
    this.textColor = scores.AALarge ? '#ffffff' : 'rgba(0,0,0,0.5)'
  }

  refineToMapBounds() {
    const bounds = this.map.getBounds()
    const ne = bounds.getNorthEast()
    const sw = bounds.getSouthWest()
    this.props.refine({
      northEast: { lat: ne.lat, lng: ne.lng },
      southWest: { lat: sw.lat, lng: sw.lng },
    })
  }

  setClusterEventHandlers = () => {
    // inspect a cluster on click
    this.map.on('click', 'properties-clusters-circle', this.zoomClusterOnClick)
    this.map.on('mousemove', 'properties-clusters-circle', () => {
      this.map.getCanvas().style.cursor = 'pointer'
    })
    this.map.on('mouseleave', 'properties-clusters-circle', () => {
      this.map.getCanvas().style.cursor = ''
    })
  }

  setMarkerEventHandlers = () => {
    // Change the cursor to a pointer when the mouse is over the places layer.
    this.map.on('mouseenter', 'properties', this.highlightPropertyOnHover)
    this.map.on('click', 'properties', this.navigateToPropertyOnClick)
    this.map.on('touchend', 'properties', this.navigateToPropertyOnClick)

    // Change it back to a pointer when it leaves.
    this.map.on('mouseleave', 'properties', () => {
      this.setState(
        {
          higlightedProperty: null,
        },
        () => {
          this.poperInstance.update()
        }
      )
      this.map.getCanvas().style.cursor = ''
      this.map.dragPan.enable()
    })
  }

  highlightPropertyOnHover = (evt) => {
    const { features, point } = evt
    // get the estate from the GeoJSON features

    const property = features[0]?.properties

    this.setState(
      {
        higlightedProperty: {
          property: Object.assign({}, property, {
            header_image: JSON.parse(property?.header_image || 'null'),
            attributes: JSON.parse(property?.attributes || 'null'),
            properties: property.properties && JSON.parse(property.properties),
          }),
          position: point,
        },
      },
      () => {
        this.poperInstance.update()
      }
    )
    this.map.getCanvas().style.cursor = 'pointer'
    this.map.dragPan.disable()
  }

  navigateToPropertyOnClick = (evt) => {
    const estate = evt.features[0].properties
    if (estate.internal_type === 'project') {
      navigate(`/${this.props.locale}/projects/${estate.id}`)
    } else {
      navigate(`/${this.props.locale}/${estate.negotiation}/${estate.id}`)
    }
  }

  zoomClusterOnClick = (evt) => {
    const features = this.map.queryRenderedFeatures(evt.point, {
      layers: ['properties-clusters-circle'],
    })
    const clusterId = features[0].properties.cluster_id
    this.map
      .getSource('properties')
      .getClusterExpansionZoom(clusterId, (err, zoom) => {
        if (err) return

        this.map.easeTo({
          center: features[0].geometry.coordinates,
          zoom: zoom,
        })
      })
  }

  popoverRef = React.createRef()
  mapContainerRef = React.createRef()

  setupPoppper = () => {
    const virtualElement = {
      getBoundingClientRect: () => {
        const containerRect = this.mapContainerRef.current?.getBoundingClientRect()
        const offsetTop = containerRect?.y ?? 0

        return {
          width: 0,
          height: 0,
          top: this.state.higlightedProperty?.position.y + offsetTop,
          right: this.state.higlightedProperty?.position.x,
          bottom: this.state.higlightedProperty?.position.y + offsetTop,
          left: this.state.higlightedProperty?.position.x,
        }
      },
    }
    this.poperInstance = createPopper(virtualElement, this.popoverRef.current, {
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [20, 20],
          },
        },
        {
          name: 'preventOverflow',
          options: {
            boundary: this.mapContainerRef.current, // true by default
          },
        },
      ],
    })
  }

  toggleSearchAsTheMapMoves = () => {
    const searchAsTheMapMoves = !this.state.searchAsTheMapMoves
    if (searchAsTheMapMoves) {
      this.refineToMapBounds()
    } else {
      this.props.refine()
    }
    this.setState({
      searchAsTheMapMoves,
    })
  }

  render() {
    return (
      <div
        className={this.props.className}
        style={{ position: 'relative', ...this.props.style }}
        ref={this.mapContainerRef}
      >
        <div
          ref={(el) => (this.mapContainer = el)}
          style={{
            height: '100%',
            width: '100%',
            position: 'relative',
            overflow: 'hidden',
          }}
        />
        <Popover
          higlightedProperty={this.state.higlightedProperty}
          ref={this.popoverRef}
        />
        <SearchOnMapMove
          checked={this.state.searchAsTheMapMoves}
          onToggle={this.toggleSearchAsTheMapMoves}
        />
      </div>
    )
  }
}

const Popover = React.forwardRef(({ higlightedProperty }, ref) => {
  return (
    <div
      ref={ref}
      style={{
        display: higlightedProperty ? 'block' : 'none',
        position: 'absolute',
        zIndex: 2000,
      }}
    >
      {higlightedProperty && (
        <PropertyCard
          property={higlightedProperty.property}
          style={{ margin: 0, width: 250 }}
        />
      )}
    </div>
  )
})

export default connectGeoSearch(Map)

function SearchOnMapMove({ checked, onToggle }) {
  return (
    <div className="c-properties__map-label">
      <div className="checkbox checkbox--inline">
        <label className="c-label" style={{ userSelect: 'none' }}>
          <input type="checkbox" checked={checked} onClick={onToggle} />{' '}
          <FormattedMessage id="search_map_checkbox_label" />
        </label>
      </div>
    </div>
  )
}
